/**
 *
 *
 * @format
 */

/* eslint-disable max-len */

/**
 * Original svg code for the save and next icon
 * <svg width="448px" height="448px" viewBox="0 0 448 448" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
 *  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
 *      <path d="M316.118,0 C328.646297,0 340.669588,4.89796549 349.627934,13.6333306 L350.059,14.059 L433.941,97.941 C442.799876,106.799811 447.838459,118.764857 447.996182,131.276197 L448,131.882 L448,400 C448,426.2449 426.937651,447.569698 400.793778,447.993569 L400,448 L48,448 C21.7551,448 0.430302,426.937651 0.00643053,400.793778 L0,400 L0,48 C0,21.7551 21.062349,0.430302 47.2062225,0.00643053 L48,0 L316.118,0 Z M229.58855,116.6485 C224.81453,111.783833 217.011829,111.783833 212.186475,116.6485 L212.186475,116.6485 L200.790425,128.137394 C195.862404,133.053813 195.965071,141.075338 200.995759,145.888253 L200.995759,145.888253 L262.852695,205.29929 L115.320054,205.29929 C108.492691,205.29929 103,210.83673 103,217.719716 L103,217.719716 L103,234.280284 C103,241.16327 108.492691,246.70071 115.320054,246.70071 L115.320054,246.70071 L262.852695,246.70071 L200.995759,306.111747 C196.016404,310.924662 195.913737,318.946187 200.790425,323.862606 L200.790425,323.862606 L212.186475,335.3515 C216.960495,340.216167 224.763196,340.216167 229.58855,335.3515 L229.58855,335.3515 L329.380984,234.74605 C334.206339,229.933135 334.206339,222.066865 329.380984,217.202198 L329.380984,217.202198 Z" id="Combined-Shape"></path>
 *  </g>
 * </svg>
 */

const saveAndNextSvgPath =
'M316.118,0 C328.646297,0 340.669588,4.89796549 349.627934,13.6333306 L350.059,14.059 L433.941,97.941 C442.799876,106.799811 447.838459,118.764857 447.996182,131.276197 L448,131.882 L448,400 C448,426.2449 426.937651,447.569698 400.793778,447.993569 L400,448 L48,448 C21.7551,448 0.430302,426.937651 0.00643053,400.793778 L0,400 L0,48 C0,21.7551 21.062349,0.430302 47.2062225,0.00643053 L48,0 L316.118,0 Z M229.58855,116.6485 C224.81453,111.783833 217.011829,111.783833 212.186475,116.6485 L212.186475,116.6485 L200.790425,128.137394 C195.862404,133.053813 195.965071,141.075338 200.995759,145.888253 L200.995759,145.888253 L262.852695,205.29929 L115.320054,205.29929 C108.492691,205.29929 103,210.83673 103,217.719716 L103,217.719716 L103,234.280284 C103,241.16327 108.492691,246.70071 115.320054,246.70071 L115.320054,246.70071 L262.852695,246.70071 L200.995759,306.111747 C196.016404,310.924662 195.913737,318.946187 200.790425,323.862606 L200.790425,323.862606 L212.186475,335.3515 C216.960495,340.216167 224.763196,340.216167 229.58855,335.3515 L229.58855,335.3515 L329.380984,234.74605 C334.206339,229.933135 334.206339,222.066865 329.380984,217.202198 L329.380984,217.202198 Z';
export const saveAndNext = {
  prefix: 'fas',
  iconName: 'save-and-next',
  icon: [448, 448, [], 'e001', saveAndNextSvgPath] };