const _excluded = ["localID"],_excluded2 = ["localID", "inserting"];function _objectWithoutProperties(source, excluded) {if (source == null) return {};var target = _objectWithoutPropertiesLoose(source, excluded);var key, i;if (Object.getOwnPropertySymbols) {var sourceSymbolKeys = Object.getOwnPropertySymbols(source);for (i = 0; i < sourceSymbolKeys.length; i++) {key = sourceSymbolKeys[i];if (excluded.indexOf(key) >= 0) continue;if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;target[key] = source[key];}}return target;}function _objectWithoutPropertiesLoose(source, excluded) {if (source == null) return {};var target = {};var sourceKeys = Object.keys(source);var key, i;for (i = 0; i < sourceKeys.length; i++) {key = sourceKeys[i];if (excluded.indexOf(key) >= 0) continue;target[key] = source[key];}return target;}function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import { actions as formActions } from 'react-redux-form';
import { RequestParams } from "../../utils/RequestParams";

import * as types from "./actionTypes";
import { notificationActions } from "../../Notifications";
import api from "../TemplatesAPI";
import ID from "../../../shared/uniqueID";
import { actions } from "../../BasicReducer";
import entitiesApi from "../../Entities/EntitiesAPI";
import templateCommonProperties from "../utils/templateCommonProperties";

export const prepareTemplate = (template) => {
  const commonPropertiesExists = template.commonProperties && template.commonProperties.length;

  const commonProperties = commonPropertiesExists ?
  template.commonProperties :
  templateCommonProperties.get();

  return _objectSpread(_objectSpread({},
  template), {}, {
    commonProperties: commonProperties.map((p) => _objectSpread(_objectSpread({}, p), {}, { localID: ID() })),
    properties: template.properties.map((p) => _objectSpread(_objectSpread({}, p), {}, { localID: ID() })) });

};

export function resetTemplate() {
  return (dispatch) => {
    dispatch(formActions.reset('template.data'));
    dispatch(formActions.setInitial('template.data'));
  };
}

export function setPropertyDefaults(getState, property) {
  const propertyWithDefaults = property;
  propertyWithDefaults.localID = ID();
  if (property.type === 'select' || property.type === 'multiselect') {
    propertyWithDefaults.content = getState().thesauris.get(0).get('_id');
  }

  if (property.type === 'relationship') {
    propertyWithDefaults.inherit = false;
    propertyWithDefaults.content = '';
  }

  if (property.type === 'nested') {
    propertyWithDefaults.nestedProperties = [{ key: '', label: '' }];
  }
  return propertyWithDefaults;
}

export function addProperty(property = {}, _index = undefined) {
  return (dispatch, getState) => {
    const properties = getState().template.data.properties.slice(0);
    const index = _index !== undefined ? _index : properties.length;
    const propertyWithDefaults = setPropertyDefaults(getState, property);
    properties.splice(index, 0, propertyWithDefaults);
    dispatch(formActions.change('template.data.properties', properties));
  };
}

export function setNestedProperties(propertyIndex, properties) {
  return (dispatch) => {
    dispatch(
    formActions.load(`template.data.properties[${propertyIndex}].nestedProperties`, properties));

  };
}

export function updateProperty(property, index) {
  return (dispatch, getState) => {
    const properties = getState().template.data.properties.slice(0);
    properties.splice(index, 1, property);
    dispatch(formActions.change('template.data.properties', properties));
  };
}

export function inserted(index) {
  return (dispatch) => {
    dispatch(formActions.change(`template.data.properties[${index}].inserting`, null));
  };
}

export function selectProperty(index) {
  return {
    type: types.SELECT_PROPERTY,
    index };

}

export function removeProperty(index) {
  return (dispatch) => {
    dispatch(formActions.remove('template.data.properties', index));
    dispatch(formActions.resetErrors('template.data'));
  };
}

export function reorderProperty(originIndex, targetIndex) {
  return (dispatch) => {
    dispatch(formActions.move('template.data.properties', originIndex, targetIndex));
  };
}

export const sanitize = (data) => {
  const commonProperties = data.commonProperties.map((prop) => {
    const { localID } = prop,sanitizedProp = _objectWithoutProperties(prop, _excluded);
    return sanitizedProp;
  });
  const properties = data.properties.map((prop) => {
    const { localID, inserting } = prop,sanitizedProp = _objectWithoutProperties(prop, _excluded2);
    if (sanitizedProp.inherit && !sanitizedProp.content) {
      sanitizedProp.inherit = false;
    }
    return sanitizedProp;
  });
  return _objectSpread(_objectSpread({}, data), {}, { properties, commonProperties });
};

export function validateMapping(template) {
  return api.validateMapping(new RequestParams(template));
}

export function saveTemplate(data) {
  let template = sanitize(data);
  return (dispatch) => {
    dispatch({ type: types.SAVING_TEMPLATE });
    return api.
    save(new RequestParams(template)).
    then((response) => {
      template = prepareTemplate(response);
      dispatch({ type: types.TEMPLATE_SAVED, data: template });
      dispatch(actions.update('templates', template));
      dispatch(formActions.merge('template.data', template));
      dispatch(notificationActions.notify('Saved successfully.', 'success'));
    }).
    catch((e) => {
      dispatch({ type: types.TEMPLATE_SAVED, data });
      throw e;
    });
  };
}

export function updateValue(model, value) {
  return (dispatch) => {
    dispatch(formActions.change(`template.data${model}`, value));
  };
}

export function countByTemplate(template) {
  return entitiesApi.countByTemplate(new RequestParams({ templateId: template._id }));
}