function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import Ajv from 'ajv';
import { objectIdSchema } from "./commonSchemas";
import { unique } from "../../api/utils/filters";
import { wrapValidator } from "../tsUtils";


const ajv = new Ajv({ allErrors: true });
ajv.addVocabulary(['tsType']);

export const emitSchemaTypes = true;

export let AccessLevels;(function (AccessLevels) {AccessLevels["READ"] = "read";AccessLevels["WRITE"] = "write";})(AccessLevels || (AccessLevels = {}));




export let MixedAccess;(function (MixedAccess) {MixedAccess["MIXED"] = "mixed";})(MixedAccess || (MixedAccess = {}));





export let PermissionType;(function (PermissionType) {PermissionType["USER"] = "user";PermissionType["GROUP"] = "group";PermissionType["PUBLIC"] = "public";})(PermissionType || (PermissionType = {}));





export const permissionType = { type: 'string', enum: Object.values(PermissionType) };
export const permissionLevel = {
  type: 'string',
  enum: Object.values(_objectSpread(_objectSpread({}, AccessLevels), MixedAccess)) };


export const permissionSchema = {
  type: 'object',
  additionalProperties: false,
  definitions: { objectIdSchema },
  properties: {
    refId: objectIdSchema,
    type: permissionType,
    level: permissionLevel },

  required: ['refId', 'type', 'level'] };


ajv.addKeyword({
  keyword: 'uniqueIds',
  type: 'object',
  errors: true,
  validate: (fields, data) => {
    const allowedIds = data.permissions.map((item) => item.refId);
    const uniqueIds = allowedIds.filter(unique);
    if (allowedIds.length !== uniqueIds.length) {
      throw new Ajv.ValidationError([
      {
        keyword: 'duplicatedPermissions',
        schemaPath: '',
        params: { keyword: 'duplicatedPermissions', fields },
        message: 'Permissions should be unique by person/group',
        instancePath: '.permissions' }]);


    }
    return true;
  } });


export const permissionsDataSchema = {
  type: 'object',
  additionalProperties: false,
  definitions: { objectIdSchema },
  properties: {
    ids: { type: 'array', items: { type: 'string' } },
    permissions: {
      type: 'array',
      items: _objectSpread({}, permissionSchema) } },


  required: ['ids', 'permissions'] };


export const validateUniquePermissions = wrapValidator(
ajv.compile({ type: 'object', uniqueIds: true }));