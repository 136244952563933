function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}





export const filterToQuery = (search) => {
  const filteredQuery = _objectSpread({}, search);
  const published = filteredQuery.publishedStatus.values.includes('published');
  const restricted = filteredQuery.publishedStatus.values.includes('restricted');

  filteredQuery.includeUnpublished = published === restricted;
  filteredQuery.unpublished = !published && restricted;

  delete filteredQuery.publishedStatus;

  return filteredQuery;
};

export const queryToFilter = (unpublished, includeUnpublished) => ({
  values: [
  ...(!unpublished ? ['published'] : []),
  ...(!includeUnpublished && unpublished || includeUnpublished && !unpublished ?
  ['restricted'] :
  [])] });