/* eslint-disable max-statements */


import { notificationActions } from "../../Notifications";
import { savePermissions } from "../PermissionsAPI";

import { wrapDispatch } from "../../Multireducer";
import {
REMOVE_DOCUMENTS_SHAREDIDS,
UPDATE_DOCUMENTS_PUBLISHED } from "../../Library/actions/actionTypes";

import { unselectAllDocuments } from "../../Library/actions/libraryActions";
import { PermissionType, MixedAccess } from "../../../shared/types/permissionSchema";

export function saveEntitiesPermissions(permissionsData, storeKey) {
  return async (dispatch, getState) => {
    const response = await savePermissions(permissionsData);
    const publicPermission = response.permissions.find((p) => p.type === PermissionType.PUBLIC);
    const publicIsMixed = (publicPermission === null || publicPermission === void 0 ? void 0 : publicPermission.level) === MixedAccess.MIXED;

    if (storeKey && !publicIsMixed) {
      const { unpublished: showingUnpublished, includeUnpublished } =
      getState()[storeKey].search || {};

      const notShowingPublicAndPrivate = showingUnpublished || !includeUnpublished;
      const toMoveFromCollection = showingUnpublished === !!publicPermission;

      const wrappedDispatch = wrapDispatch(dispatch, storeKey);

      if (notShowingPublicAndPrivate) {
        if (toMoveFromCollection) {
          wrappedDispatch({
            type: REMOVE_DOCUMENTS_SHAREDIDS,
            sharedIds: permissionsData.ids });


          wrappedDispatch(unselectAllDocuments());
        }
      } else {
        wrappedDispatch({
          type: UPDATE_DOCUMENTS_PUBLISHED,
          sharedIds: permissionsData.ids,
          published: !!publicPermission });

      }
    }

    dispatch(notificationActions.notify('Update success', 'success'));
  };
}