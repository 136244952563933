import api from "../../utils/api";

const postToOcr = async (filename) => {
  await api.post(`files/${filename}/ocr`);
};

const getOcrStatus = async (filename) => {
  const {
    json: { status, lastUpdated } } =
  await api.get(`files/${filename}/ocr`);

  return { status, lastUpdated };
};

export { postToOcr, getOcrStatus };