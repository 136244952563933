
import { actions } from 'react-redux-form';
import { readFileAsBase64 } from "../../Library/actions/saveEntityWithFiles";
import uniqueID from "../../../shared/uniqueID";
import * as types from "../../Attachments/actions/actionTypes";

const uploadLocalAttachment =
(
entitySharedId,
file,
storeKeys,
fileLocalID) =>

async (dispatch) =>
readFileAsBase64(file, (info) => {
  const newFile = {
    originalname: file.name,
    filename: file.name,
    serializedFile: info,
    type: 'attachment',
    mimetype: file.type,
    entity: entitySharedId,
    fileLocalID: fileLocalID || uniqueID() };

  dispatch(actions.push(`${storeKeys.model}.attachments`, newFile));
  dispatch({ type: types.ATTACHMENT_PROGRESS, entity: entitySharedId, progress: 100 });
});

const uploadLocalAttachmentFromUrl =
(
entitySharedId,
formData,
storeKeys) =>

(dispatch) => {
  const { name, url } = formData;
  const newUrl = {
    originalname: name,
    url,
    entity: entitySharedId };

  dispatch(actions.push(`${storeKeys.model}.attachments`, newUrl));
  dispatch({ type: types.ATTACHMENT_PROGRESS, entity: entitySharedId, progress: 100 });
};

const attachmentCompleted = (entitySharedId) => (dispatch) => {
  dispatch({ type: types.ATTACHMENT_LOCAL_COMPLETE, entity: entitySharedId });
};

export { uploadLocalAttachment, uploadLocalAttachmentFromUrl, attachmentCompleted };