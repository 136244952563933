function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import { actions as basicActions } from "../../BasicReducer";
import { notificationActions } from "../../Notifications";
import { RequestParams } from "../../utils/RequestParams";




import UsersAPI from "../UsersAPI";

export function deleteUser(user) {
  return (dispatch) =>
  UsersAPI.delete(new RequestParams(user)).then(() => {
    dispatch(basicActions.remove('users', user));
    dispatch(notificationActions.notify('Deleted successfully.', 'success'));
  });
}

export function saveUser(user) {
  return async (dispatch) => {
    await UsersAPI.save(new RequestParams(user));
    const userToNotify = _objectSpread({}, user);
    delete userToNotify.password;
    dispatch(basicActions.update('users', userToNotify));
    dispatch(notificationActions.notify('Saved successfully.', 'success'));
  };
}

export function newUser(user) {
  return async (dispatch) => {
    const createdUser = await UsersAPI.new(new RequestParams(user));
    const userToNotify = _objectSpread(_objectSpread({}, user), createdUser);
    delete userToNotify.password;
    dispatch(basicActions.push('users', userToNotify));
    dispatch(notificationActions.notify('Created successfully.', 'success'));
  };
}

export function loadUsers() {
  return async (dispatch) => {
    const users = await UsersAPI.get(new RequestParams());
    dispatch(basicActions.set('users', users));
  };
}