import {
fetchSearches,
setEditSearchEntities,
editSearchEntities,
selectSemanticSearchDocument,
unselectSemanticSearchDocument,
submitNewSearch,
showSemanticSearch,
hideSemanticSearch,
deleteSearch,
stopSearch,
resumeSearch,
registerForUpdates,
updateSearch,
addSearchResults,
getSearch,
getMoreSearchResults } from "./actions";


export default {
  fetchSearches,
  setEditSearchEntities,
  editSearchEntities,
  selectSemanticSearchDocument,
  unselectSemanticSearchDocument,
  submitNewSearch,
  showSemanticSearch,
  hideSemanticSearch,
  deleteSearch,
  stopSearch,
  resumeSearch,
  registerForUpdates,
  updateSearch,
  addSearchResults,
  getSearch,
  getMoreSearchResults };