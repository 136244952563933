function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}


import { actions } from "../../../../BasicReducer";
import { RequestParams } from "../../../../utils/RequestParams";
import { notificationActions } from "../../../../Notifications";
import { ensure } from "../../../../../shared/tsUtils";
import { deleteGroup, getGroups, saveGroup } from "../UserGroupsAPI";

export function loadUserGroups() {
  return async (dispatch) => {
    const userGroups = await getGroups(new RequestParams());
    dispatch(actions.set('userGroups', userGroups));
  };
}

export function saveUserGroup(userGroup) {
  return async (dispatch) => {
    const savedUserGroup = await saveGroup(new RequestParams(userGroup));
    const userGroupToDispatch = _objectSpread(_objectSpread({}, savedUserGroup), {}, { members: userGroup.members });
    if (userGroup._id) {
      dispatch(actions.update('userGroups', userGroupToDispatch));
      dispatch(notificationActions.notify('Group updated', 'success'));
    } else {
      dispatch(actions.push('userGroups', userGroupToDispatch));
      dispatch(notificationActions.notify('Group created', 'success'));
    }
  };
}

export function deleteUserGroup(userGroup) {
  return async (dispatch) => {
    await deleteGroup(new RequestParams({ _id: ensure(userGroup._id) }));
    dispatch(actions.remove('userGroups', userGroup));
    dispatch(notificationActions.notify('Group deleted', 'success'));
  };
}