export default {
  checkbox: 'check',
  numeric: 'calculator',
  relationship: 'exchange-alt',
  relationshipfilter: 'filter',
  select: 'sort',
  multiselect: 'list',
  date: 'calendar',
  text: 'font',
  markdown: 'file',
  nested: 'cubes',
  multidate: 'calendar',
  multidaterange: 'calendar',
  daterange: 'calendar',
  image: 'image',
  preview: 'passport',
  media: 'video',
  geolocation: 'map-marker',
  link: 'link',
  generatedid: 'fingerprint' };