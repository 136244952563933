/** @format */

import api from "../utils/api";











export default {
  async setSecret(requestParams) {
    const response = await api.post('auth2fa-secret', requestParams);
    return response.json;
  },

  async enable(requestParams) {
    const response = await api.post('auth2fa-enable', requestParams);
    return response.json;
  },

  async reset2fa(requestParams) {
    const response = await api.post('auth2fa-reset', requestParams);
    return response.json;
  } };